import getDevSidebar from "../../customApp/sidebar";
const options = [
  // {
  //   key: "home",
  //   label: "Inicio",
  //   leftIcon: "ion-arrow-graph-up-right"
  // },
  // {
  //   key: "design",
  //   label: "Diseño",
  //   leftIcon: "ion-leaf",
  //   children: [
  //     {
  //       key: "design/home",
  //       label: "Home"
  //     }
  //   ]
  // },
  // {
  //   key: "news",
  //   label: "Noticias",
  //   leftIcon: "ion-ios-paper"
  // },
  ...getDevSidebar
];
export default options;
