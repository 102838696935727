import asyncComponent from "../helpers/AsyncFunc";

const routes = [
  {
    path: "sepa_export_last_year",
    component: asyncComponent(() => import("./components/payment/exportSepa")),
  },
  {
    path: "export_pendants",
    component: asyncComponent(() =>
      import("./components/payment/pendantExport")
    ),
  },
  {
    path: "export_platform",
    component: asyncComponent(() =>
      import("./components/payment/exportPlatform")
    ),
  },
  {
    path: "news",
    component: asyncComponent(() => import("./components/news/news")),
  },
  {
    path: "news/create",
    component: asyncComponent(() => import("./components/news/create")),
  },
  {
    path: "news/edit/:id_news",
    component: asyncComponent(() => import("./components/news/edit")),
  },
  {
    path: "topicality",
    component: asyncComponent(() =>
      import("./components/topicality/topicality")
    ),
  },
  {
    path: "topicality/create",
    component: asyncComponent(() => import("./components/topicality/create")),
  },
  {
    path: "topicality/edit/:id_topicality",
    component: asyncComponent(() => import("./components/topicality/edit")),
  },
  {
    path: "topicality/categories",
    component: asyncComponent(() =>
      import("./components/topicality/categories/categories")
    ),
  },
  {
    path: "topicality/categories/edit/:id_category",
    component: asyncComponent(() =>
      import("./components/topicality/categories/edit")
    ),
  },
  {
    path: "lectures",
    component: asyncComponent(() => import("./components/lectures/lectures")),
  },
  {
    path: "lectures/create",
    component: asyncComponent(() => import("./components/lectures/create")),
  },
  {
    path: "lectures/edit/:id_lectures",
    component: asyncComponent(() => import("./components/lectures/edit")),
  },
  {
    path: "lectures/categories",
    component: asyncComponent(() =>
      import("./components/lectures/categories/categories")
    ),
  },
  {
    path: "lectures/categories/edit/:id_category",
    component: asyncComponent(() =>
      import("./components/lectures/categories/edit")
    ),
  },
  {
    path: "multimedia",
    component: asyncComponent(() =>
      import("./components/multimedia/multimedia")
    ),
  },
  {
    path: "multimedia/create",
    component: asyncComponent(() => import("./components/multimedia/create")),
  },
  {
    path: "multimedia/edit/:id_multimedia",
    component: asyncComponent(() => import("./components/multimedia/edit")),
  },
  {
    path: "multimedia/categories",
    component: asyncComponent(() =>
      import("./components/multimedia/categories/categories")
    ),
  },
  {
    path: "multimedia/categories/edit/:id_category",
    component: asyncComponent(() =>
      import("./components/multimedia/categories/edit")
    ),
  },
  {
    path: "design/home",
    component: asyncComponent(() => import("./components/design/home/home")),
  },
  {
    path: "design/footer",
    component: asyncComponent(() =>
      import("./components/design/footer/footer")
    ),
  },
  {
    path: "design/templates",
    component: asyncComponent(() =>
      import("./components/design/templates/templates")
    ),
  },

  {
    path: "design/comites",
    component: asyncComponent(() =>
      import("./components/design/comites/comites")
    ),
  },

  {
    path: "news/categories",
    component: asyncComponent(() =>
      import("./components/news/categories/categories")
    ),
  },
  {
    path: "news/categories/edit/:id_category",
    component: asyncComponent(() =>
      import("./components/news/categories/edit")
    ),
  },
  {
    path: "news/tags",
    component: asyncComponent(() => import("./components/news/tags/tags")),
  },
  {
    path: "news/tags/edit/:id_tag",
    component: asyncComponent(() => import("./components/news/tags/edit")),
  },
  {
    path: "pages/create",
    component: asyncComponent(() => import("./components/pages/create")),
  },
  {
    path: "pages/create/:id_page",
    component: asyncComponent(() => import("./components/pages/createSubpage")),
  },
  {
    path: "pages/edit/:id_page",
    component: asyncComponent(() => import("./components/pages/edit")),
  },
  {
    path: "pages/edit-sub/:id_sub_page",
    component: asyncComponent(() => import("./components/pages/editSubpage")),
  },
  {
    path: "pages/fes",
    component: asyncComponent(() => import("./components/pages/fes")),
  },
  {
    path: "pages/members",
    component: asyncComponent(() => import("./components/pages/members")),
  },
  {
    path: "pages/activities",
    component: asyncComponent(() => import("./components/pages/activities")),
  },
  {
    path: "pages/find-out",
    component: asyncComponent(() => import("./components/pages/findOut")),
  },
  {
    path: "pages/publications",
    component: asyncComponent(() => import("./components/pages/publications")),
  },
  {
    path: "pages/means",
    component: asyncComponent(() => import("./components/pages/means")),
  },
  {
    path: "pages/extra",
    component: asyncComponent(() => import("./components/pages/extraPages")),
  },
  {
    path: "users",
    component: asyncComponent(() => import("./components/users/users")),
  },
  {
    path: "users/create",
    component: asyncComponent(() => import("./components/users/create")),
  },
  {
    path: "users/admin",
    component: asyncComponent(() => import("./components/users/admin")),
  },
  {
    path: "users/edit/:id_user",
    component: asyncComponent(() => import("./components/users/edit")),
  },
  {
    path: "users/edit_collective/:id_user",
    component: asyncComponent(() =>
      import("./components/users/editCollective")
    ),
  },
  {
    path: "payment",
    component: asyncComponent(() => import("./components/payment/payment")),
  },
  {
    path: "payment/payment_dates",
    component: asyncComponent(() =>
      import("./components/payment/payment_dates")
    ),
  },
  {
    path: "payment/edit/:idPayment",
    component: asyncComponent(() => import("./components/payment/edit")),
  },
  {
    path: "payment/invoices",
    component: asyncComponent(() => import("./components/payment/invoices")),
  },
  {
    path: "payment/endYear",
    component: asyncComponent(() => import("./components/payment/endYear")),
  },
  {
    path: "settings/cta",
    component: asyncComponent(() => import("./components/settings/cta")),
  },
  {
    path: "settings/newsletter",
    component: asyncComponent(() => import("./components/settings/newsletter")),
  },
  {
    path: "activities",
    component: asyncComponent(() =>
      import("./components/activities/activities")
    ),
  },
  {
    path: "activities/create/doctoral-school",
    component: asyncComponent(() => import("./components/activities/createDS")),
  },
  {
    path: "activities/create/general",
    component: asyncComponent(() =>
      import("./components/activities/createGeneral")
    ),
  },
  {
    path: "activities/edit/:id_activities/:type",
    component: asyncComponent(() => import("./components/activities/edit")),
  },
  {
    path: "activities/categories",
    component: asyncComponent(() =>
      import("./components/activities/categories/categories")
    ),
  },
  {
    path: "activities/categories/edit/:id_category",
    component: asyncComponent(() =>
      import("./components/activities/categories/edit")
    ),
  },
  {
    path: "comites",
    component: asyncComponent(() => import("./components/comites/comites")),
  },
  {
    path: "comites/create",
    component: asyncComponent(() => import("./components/comites/create")),
  },
  {
    path: "comites/edit/:id",
    component: asyncComponent(() => import("./components/comites/edit")),
  },
  {
    path: "comites/repositoryDocs",
    component: asyncComponent(() =>
      import("./components/comites/respositoryDocs")
    ),
  },
  {
    path: "comites/repositoryDocs/:id",
    component: asyncComponent(() =>
      import("./components/comites/repository/repositorybyComite")
    ),
  },
  {
    path: "votes/:id_votes/candidates",
    component: asyncComponent(() => import("./components/votes/candidates")),
  },
  {
    path: "votes/create",
    component: asyncComponent(() => import("./components/votes/create")),
  },
  {
    path: "votes",
    component: asyncComponent(() => import("./components/votes/votes")),
  },

  {
    path: "votes/candidates",
    component: asyncComponent(() => import("./components/votes/candidates")),
  },
  {
    path: "votes/results",
    component: asyncComponent(() => import("./components/votes/results")),
  },
  {
    path: "products",
    component: asyncComponent(() => import("./components/products/products")),
  },
  {
    path: "products/create",
    component: asyncComponent(() => import("./components/products/create")),
  },
  {
    path: "products/edit/:id",
    component: asyncComponent(() => import("./components/products/edit")),
  },
  {
    path: "products/categories",
    component: asyncComponent(() => import("./components/products/categories")),
  },
  {
    path: "products/categories/edit/:id",
    component: asyncComponent(() =>
      import("./components/products/editCategories")
    ),
  },

  {
    path: "newsletter/create",
    component: asyncComponent(() => import("./components/newsletter/create")),
  },
  /*{
    path: "newsletter/create",
    component: asyncComponent(() => import("./components/newsletter/createOld")),
  },*/
  {
    path: "newsletter/subscribers",
    component: asyncComponent(() =>
      import("./components/newsletter/subscribers")
    ),
  },
  {
    path: "newsletter/list",
    component: asyncComponent(() => import("./components/newsletter/list")),
  },
  {
    path: "votes_assembly/:id_votes/candidates",
    component: asyncComponent(() =>
      import("./components/votes_assembly/candidates")
    ),
  },
  {
    path: "votes_assembly/create",
    component: asyncComponent(() =>
      import("./components/votes_assembly/create")
    ),
  },
  {
    path: "votes_assembly",
    component: asyncComponent(() =>
      import("./components/votes_assembly/votes")
    ),
  },
  {
    path: "votes_assembly/candidates",
    component: asyncComponent(() =>
      import("./components/votes_assembly/candidates")
    ),
  },
  {
    path: "votes_assembly/results",
    component: asyncComponent(() =>
      import("./components/votes_assembly/results")
    ),
  },
  {
    path: "votes_ce",
    component: asyncComponent(() => import("./components/votes_ce/votes")),
  },
  {
    path: "votes_ce/:id_votes/candidates",
    component: asyncComponent(() => import("./components/votes_ce/candidates")),
  },
  {
    path: "votes_ce/create",
    component: asyncComponent(() => import("./components/votes_ce/create")),
  },
];
export default routes;
