const options = [
  {
    key: "",
    label: "Inicio",
    leftIcon: "ion-arrow-graph-up-right",
  },
  {
    key: "news",
    label: "Noticias",
    leftIcon: "ion-ios-paper",
    children: [
      {
        key: "news/create",
        label: "Crear entrada",
      },
      {
        key: "news",
        label: "Entradas",
      },
      {
        key: "news/categories",
        label: "Categorías",
      },
    ],
  },
  {
    key: "topicality",
    label: "Actualidad",
    leftIcon: "ion-android-globe",
    children: [
      {
        key: "topicality/create",
        label: "Crear entrada",
      },
      {
        key: "topicality",
        label: "Entradas",
      },
      {
        key: "topicality/categories",
        label: "Categorías",
      },
    ],
  },

  {
    key: "multimedia",
    label: "Multimedia",
    leftIcon: "ion-ios-film",
    children: [
      {
        key: "multimedia/create",
        label: "Crear entrada",
      },
      {
        key: "multimedia",
        label: "Entradas",
      },
      {
        key: "multimedia/categories",
        label: "Categorías",
      },
    ],
  },
  {
    key: "design",
    label: "Home",
    leftIcon: "ion-android-color-palette",
    children: [
      {
        key: "design/home",
        label: "Principal",
      },
      {
        key: "design/footer",
        label: "Pie de página",
      },
      {
        key: "settings/cta",
        label: "CTA",
      },
      {
        key: "settings/newsletter",
        label: "Boletín",
      },
    ],
  },
  {
    key: "comicomitesters",
    label: "Comites",
    leftIcon: "ion-person",
    children: [
      {
        key: "comites",
        label: "Ver comites",
      },
      {
        key: "comites/create",
        label: "Crear comite",
      },
      {
        key: "comites/repositoryDocs",
        label: "Repositorio",
      },
    ],
  },
  {
    key: "pages",
    label: "Páginas",
    leftIcon: "ion-code",
    children: [
      {
        key: "pages/create",
        label: "Crear página",
      },
      {
        key: "pages/fes",
        label: "FES",
      },
      {
        key: "pages/members",
        label: "Miembros",
      },
      {
        key: "pages/activities",
        label: "Actividades",
      },
      {
        key: "pages/publications",
        label: "Publicaciones",
      },
      {
        key: "pages/means",
        label: "Recursos",
      },
      {
        key: "pages/find-out",
        label: "Divulgación",
      },
      {
        key: "pages/extra",
        label: "Páginas extra",
      },
    ],
  },
  {
    key: "lectures",
    label: "Formación y eventos",
    leftIcon: "ion-android-plane",
    children: [
      {
        key: "lectures/create",
        label: "Crear entrada",
      },
      {
        key: "lectures",
        label: "Entradas",
      },
      {
        key: "lectures/categories",
        label: "Categorías",
      },
    ],
  },
  {
    key: "activities",
    label: "Escuela de Doctorado",
    leftIcon: "ion-android-happy",
    children: [
      // {
      //   key: "activities/create/general",
      //   label: "Crear entrada General",
      // },
      {
        key: "activities/create/doctoral-school",
        label: "Crear entrada",
      },
      {
        key: "activities",
        label: "Ver entradas",
      },
      {
        key: "activities/categories",
        label: "Categorías",
      },
    ],
  },

  // {
  //   key: "settings",
  //   label: "Configuración",
  //   leftIcon: "ion-android-settings",
  //   children: [
  //     {
  //       key: "settings/cta",
  //       label: "CTA",
  //     },
  //     {
  //       key: "settings/newsletter",
  //       label: "Newsletter",
  //     },
  //   ],
  // },

  {
    key: "users",
    label: "Usuarios",
    leftIcon: "ion-android-contacts",
    children: [
      {
        key: "users",
        label: "Lista",
      },
      {
        key: "users/admin",
        label: "Admin",
      },
      // {
      // 	key: "users/create",
      // 	label: "Dar de alta",
      // },
    ],
  },
  {
    key: "payments",
    label: "Pedidos",
    leftIcon: "ion-card",
    children: [
      {
        key: "payment",
        label: "Lista",
      },
      {
        key: "payment/endYear",
        label: "Fecha inicio nuevo año",
      },
      {
        key: "payment/invoices",
        label: "Facturas emitidas",
      },
    ],
  },
  {
    key: "renovations",
    label: "Renovaciones",
    leftIcon: "ion-arrow-return-right",
    children: [
      {
        key: "payment/payment_dates",
        label: "Apert. fechas de pago",
      },
      {
        key: "export_pendants",
        label: "Exp. pendientes",
      },
      {
        key: "export_platform",
        label: "Enviar a la plataforma",
      },
      {
        key: "sepa_export_last_year",
        label: "Exp. sepa año ant.",
      },
    ],
  },
  {
    key: "products",
    label: "Productos",
    leftIcon: "ion-android-cart",
    children: [
      {
        key: "products",
        label: "Ver productos",
      },
      {
        key: "products/create",
        label: "Crear producto",
      },
      {
        key: "products/categories",
        label: "Categorías",
      },
    ],
  },
  {
    key: "votes",
    label: "Elecciones comités",
    leftIcon: "ion-ribbon-b",
    children: [
      {
        key: "votes/create",
        label: "Crear",
      },
      {
        key: "votes",
        label: "Ver convocatoria",
      },
      {
        key: "votes/results",
        label: "Resultados",
      },
    ],
  },
  {
    key: "votes_assembly",
    label: "Elecciones Asamblea",
    leftIcon: "ion-ios-filing",
    children: [
      {
        key: "votes_assembly/create",
        label: "Crear",
      },
      {
        key: "votes_assembly",
        label: "Ver convocatoria",
      },
      {
        key: "votes_assembly/results",
        label: "Resultados",
      },
    ],
  },
  {
    key: "votes_ce",
    label: "Elecciones CE",
    leftIcon: "ion-archive",
    children: [
      {
        key: "votes_ce/create",
        label: "Crear",
      },
      {
        key: "votes_ce",
        label: "Ver convocatoria",
      },
    ],
  },
  {
    key: "newsletter",
    label: "Newsletter",
    leftIcon: "ion-email",
    children: [
      {
        key: "newsletter/create",
        label: "Crear newsletter",
      },
      {
        key: "newsletter/subscribers",
        label: "Listado de usuarios",
      },
      {
        key: "newsletter/list",
        label: "Repositorio",
      },
    ],
  },
];
export default options;
