import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        <Route
          exact
          path={"/404"}
          component={asyncComponent(() => import("./containers/Page/404"))}
        />
        <Route
          exact
          path={"/500"}
          component={asyncComponent(() => import("./containers/Page/500"))}
        />
        <Route
          exact
          path={"/signin"}
          component={asyncComponent(() => import("./containers/Page/signin"))}
        />
        {/* <Route
          exact
          path={"/signup"}
          component={asyncComponent(() => import("./containers/Page/signup"))}
        />
        <Route
          exact
          path={"/forgotpassword"}
          component={asyncComponent(() =>
            import("./containers/Page/forgotPassword")
          )}
        />
        <Route
          exact
          path={"/resetpassword"}
          component={asyncComponent(() =>
            import("./containers/Page/resetPassword")
          )}
        /> */}

        <RestrictedRoute
          path="/dashboard"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Design */}
        <RestrictedRoute
          path="/dashboard/design"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        {/* News */}
        <RestrictedRoute
          path="/dashboard/news"
          component={App}
          isLoggedIn={isLoggedIn}
        />
        {/* Multimedia */}
        <RestrictedRoute
          path="/dashboard/multimedia"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Pages */}
        <RestrictedRoute
          path="/dashboard/pages"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Actualidad */}
        <RestrictedRoute
          path="/dashboard/topicality"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Activities */}
        <RestrictedRoute
          path="/dashboard/activities"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Workshops */}
        <RestrictedRoute
          path="/dashboard/workshops"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Lectures */}
        <RestrictedRoute
          path="/dashboard/lectured"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Events */}
        <RestrictedRoute
          path="/dashboard/events"
          component={App}
          isLoggedIn={isLoggedIn}
        />

        {/* Settings */}
        <RestrictedRoute
          path="/dashboard/settings"
          component={App}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.idToken !== null || state.idToken !== null,
}))(PublicRoutes);
