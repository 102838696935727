import React, { Component } from "react";

let totalPrice;
class TopbarAddtoCart extends Component {
  
  render() {
    return(<div></div>)
   
  }
}

export default TopbarAddtoCart;
